<template>
  <div class="kit-tasks">
    <h1 class="subtitle-1 grey--text">Файлы</h1>

    <TaskDialogCreate :patterns="patterns" :user="user" />

    <v-container>
      <TaskList :tasks="tasks" />
    </v-container>
  </div>
</template>

<script>
import TaskDialogCreate from '@/components/task/TaskDialogCreate'
import TaskList from '@/components/task/TaskList'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      patterns: [
        {
          title: 'Тарифы на билеты в парк',
          directory: 'C:\\MSCo\\SaleAutomat\\Media\\Photo\\Bilet\\',
          file: 'bilet.lst'
        },
        {
          title: 'Тарифы на экскурсии',
          directory: 'C:\\MSCo\\SaleAutomat\\Media\\Photo\\Excursions\\',
          file: 'exc_price.lst'
        },
        {
          title: 'Тарифы на конные прогулки',
          directory: 'C:\\MSCo\\SaleAutomat\\Media\\Photo\\Horses\\',
          file: 'horse_ride.lst'
        },
        {
          title: 'Сувениры',
          directory: 'C:\\MSCo\\SaleAutomat\\Media\\Photo\\Goods\\',
          file: 'goods.lst'
        },
        {
          title: 'Штрих-коды билетов в парк',
          directory: 'C:\\MSCo\\TSI_Obmen\\',
          file: 'barcodes_in.csv'
        },
        {
          title: 'Штрих-коды билетов в парк льготные',
          directory: 'C:\\MSCo\\TSI_Obmen\\',
          file: 'barcodes_lg_in.csv'
        }
      ]
    }
  },
  components: {
    TaskDialogCreate,
    TaskList
  },
  computed: {
    kitId() {
      return this.$route.params.kitId
    },
    ...mapGetters({
      tasks: 'kit/tasks',
      user: 'auth/user'
    })
  },
  mounted() {
    this.fetchTasks(this.kitId)
  },
  methods: {
    ...mapActions('kit', ['fetchTasks'])
  },
  watch: {
    file(updatedFile) {
      if (!this.title) {
        this.title = updatedFile.name
      }
    }
  }
}
</script>
