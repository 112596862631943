<template>
  <div class="text-right">
    <v-dialog v-model="dialog" max-width="500">
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on">
          <v-icon left>mdi-plus</v-icon>
          <span>Добавить</span>
        </v-btn>
      </template>
      <v-card class="task-create-card px-4" outlined>
        <v-form ref="taskForm" enctype="multipart/form-data" @submit.prevent="checkInput">
          <v-card-title>Отправить файл</v-card-title>
          <v-card-text>
            <span class="font-weight-medium">Исходный файл</span>
            <v-file-input v-model="task.file" :rules="fileRules" :show-size="1000" hide-details="auto" class="mt-1 mb-3" single-line outlined dense>
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
            <span class="font-weight-medium">Шаблон</span>
            <v-select
              v-model="selectedPattern"
              :items="patterns"
              hide-details="auto"
              item-text="title"
              class="mt-1 mb-3"
              return-object
              single-line
              clearable
              outlined
              dense
            />
            <span class="font-weight-medium">Имя конечного файла</span>
            <v-text-field
              v-model="task.title"
              :rules="titleRules"
              :disabled="typeof selectedPattern === 'object' || user.id !== 1"
              hide-details="auto"
              class="mt-1 mb-3"
              single-line
              outlined
              dense
            />
            <span class="font-weight-medium">Директория загрузки</span>
            <v-text-field
              v-model="task.directory"
              :rules="directoryRules"
              :disabled="typeof selectedPattern === 'object' || user.id !== 1"
              hide-details="auto"
              class="mt-1"
              single-line
              outlined
              dense
            />
          </v-card-text>
          <v-card-actions class="mb-2">
            <v-spacer></v-spacer>
            <v-btn :loading="loading" type="submit" class="text-capitalize" color="primary" depressed>Отправить</v-btn>
            <v-btn text class="mx-2 text-none" color="grey darken-3" @click="closeDialog">Отмена</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    patterns: {
      type: Array,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      selectedPattern: undefined,
      task: this.createTaskStub(),
      fileRules: [(v) => !!v || 'Обязательное поле'],
      titleRules: [(v) => !!v || 'Обязательное поле'],
      directoryRules: [(v) => !!v || 'Обязательное поле']
    }
  },
  computed: {
    kitId() {
      return this.$route.params.kitId
    }
  },
  methods: {
    checkInput() {
      if (this.$refs.taskForm.validate()) {
        this.loading = true
        this.submitData()
      }
    },
    submitData() {
      const formData = new FormData()
      formData.append('file', this.task.file)
      formData.append('title', this.task.title)
      formData.append('directory', this.task.directory)

      this.createTask({ kitId: this.kitId, taskData: formData })
        .then(() => {
          this.loading = false
          this.closeDialog()
        })
        .catch(() => {
          this.loading = false
        })
    },
    closeDialog() {
      this.dialog = false
      this.$refs.taskForm.reset()
      this.task = this.createTaskStub()
    },
    createTaskStub() {
      return {
        file: null,
        title: '',
        directory: 'C:\\'
      }
    },
    ...mapActions('kit', ['createTask'])
  },
  watch: {
    selectedPattern(pattern) {
      if (pattern) {
        this.task.title = pattern.file
        this.task.directory = pattern.directory
      }
    }
  }
}
</script>
<style scoped>
.task-create-card .v-btn {
  font-weight: 400;
}
</style>
