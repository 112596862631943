<template>
  <div>
    <div v-if="tasks.length === 0" class="text-center">Вы еще не загружали файлы</div>
    <TaskListItem v-for="task in tasks" :task="task" :key="task.id" />
  </div>
</template>

<script>
import TaskListItem from './TaskListItem'
export default {
  props: {
    tasks: {
      type: Array,
      required: true
    }
  },
  components: {
    TaskListItem
  }
}
</script>

<style></style>
