<template>
  <v-card flat>
    <v-row wrap>
      <v-col md="3">
        <div class="caption grey--text">Путь к конечному файлу</div>
        <div>{{ task.fileDirectory }}{{ task.fileName }}</div>
      </v-col>
      <v-col md="3">
        <div class="caption grey--text">Файл</div>
        <div>{{ task.fileUrl }}</div>
      </v-col>
      <v-col md="4">
        <div class="caption grey--text">Статус</div>
        <div>{{ task.statusComment }}</div>
      </v-col>
      <v-col md="2">
        <div class="caption grey--text">Статус обновлен</div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              {{ task.updatedAt | fromNow }}
            </div>
          </template>
          {{ task.updatedAt | format }}
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-divider></v-divider>
    </v-row>
  </v-card>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    task: Object
  },
  filters: {
    format(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    fromNow(date) {
      return moment(date).fromNow()
    }
  }
}
</script>
